
import { Options, Vue, Watch } from "vue-property-decorator";
@Options({
  name: "Environmentaltest",
  components: {},
})
export default class extends Vue {
  skipRouter = "/app/environmentaltest/controlpanel/controlpanelpage";
  @Watch("$route")
  watchrouter(route: any) {
    if (
      this.$route.fullPath.indexOf("/app/environmentaltest/controlpanel") !== -1
    ) {
      this.skipRouter = this.$route.fullPath;
    } else {
      this.skipRouter = "/app/environmentaltest/controlpanel/controlpanelpage";
    }
  }
}
